import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import FramedContainer from "../framed-container/FramedContainer";
import Markdown from "../Markdown";

function GeneratedExpansionDetail({ expansions, title }) {
  // TODO add loading spinner
  return (
    expansions.length > 0 && (
      <>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          {title}:
        </Typography>
        {expansions.map((expansion, index) => {
          return (
            <FramedContainer key={index}>
              <Box sx={{ width: "100%" }}>
                <Markdown content={expansion.response}></Markdown>
              </Box>
            </FramedContainer>
          );
        })}
      </>
    )
  );
}

export default GeneratedExpansionDetail;
