import { Box, FormLabel, styled, TextareaAutosize } from "@mui/material";

const StyledTextarea = styled(TextareaAutosize)(
    () => `
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 16.5px 14px;
    border-radius: 8px;
    border: 1px solid #DAE2ED;
    box-shadow: 0 2px 2px #F3F6F9;

    &:hover {
      border-color: black;
    }

    &:focus {
      border-color: #3399FF;
      box-shadow: 0 0 0 3px #b6daff;
    }

    /* firefox */
    &:focus-visible {
      outline: 0;
    }
  `,
);

function TextArea({
                      id,
                      label,
                      value,
                      onChange,
                      placeholder = "",
                      disabled = false,
                      minRows = 3,
                  }) {
    return (
        <Box width="100%">
            <FormLabel id={id}>{label}</FormLabel>
            <StyledTextarea
                aria-labelledby={id}
                variant="outlined"
                sx={{ width: "100%" }}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                minRows={minRows}
            />
        </Box>
    );
}

export default TextArea;
