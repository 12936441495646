import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import FramedContainer from "../framed-container/FramedContainer";
import FilledTextButton from "../buttons/FilledTextButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Markdown from "../Markdown";
import { useApplication } from "../../providers/applicationProvider";

function GeneratedContentPlanningActivities({ generatedContentPlanning }) {
  const [isExpandingActivities, setExpandingActivities] = useState(false);
  const [activities, setActivities] = useState();

  const application = useApplication();

  const expandActivities = () => {
    setExpandingActivities(true);
    application
      .apiClient()
      .expandActivities(
        generatedContentPlanning.content(),
        generatedContentPlanning.id(),
      )
      .then((response) => {
        setExpandingActivities(false);
        setActivities(response.content);
      })
      .catch(() => {
        setExpandingActivities(false);
        setActivities(undefined);
      });
  };

  const renderActivitiesDetail = () => {
    if (activities) {
      return (
        <FramedContainer>
          <Box sx={{ width: "100%" }}>
            <Markdown content={activities}></Markdown>
          </Box>
        </FramedContainer>
      );
    }
  };

  const isContentPlanningGenerated = () => {
    return generatedContentPlanning !== undefined;
  };

  const renderActivities = () => {
    if (isContentPlanningGenerated()) {
      const btnText = isExpandingActivities ? "Expandiendo.." : "Expandir";
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography component="span">Actividades</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FilledTextButton
              onClick={expandActivities}
              disabled={isExpandingActivities}
              text={btnText}
            />
            {renderActivitiesDetail()}
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  return isContentPlanningGenerated() ? renderActivities() : undefined;
}

export default GeneratedContentPlanningActivities;
