import GeneratedContentPlanning from "../../app/GeneratedContentPlanning";

export default class GeneratedContentPlanningsAdapter {
  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  adapt() {
    const contentPlannings = this._jsonResponse.content;
    return contentPlannings.map(
      (contentPlanning) =>
        new GeneratedContentPlanning(
          contentPlanning.id,
          contentPlanning.grade,
          contentPlanning.instruction,
          contentPlanning.region,
          contentPlanning.subject,
          contentPlanning.response,
          contentPlanning.created_at,
          contentPlanning.topic,
          contentPlanning.time_unit,
          contentPlanning.app_version,
          contentPlanning.context_files_names,
          contentPlanning.public_id,
          contentPlanning.title
        ),
    );
  }
}
