import { Box, CircularProgress } from "@mui/material";
import React from "react";

function LoadableContent({ isLoading, children, text = undefined }) {
  const style = styles();

  if (isLoading)
    return (
      <Box m="auto" sx={style.mainContainer}>
        <CircularProgress size="5rem" />
        {text && <p>{text}</p>}
      </Box>
    );
  else return children;
}

export default LoadableContent;

const styles = () => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    alignItems: "center",
  },
});
