import { Box, Typography } from "@mui/material";

export default function FeedbackStatBox({ isPositive, amount }) {
  const style = styles();
  const title = isPositive ? "Feedbacks positivo" : "Feedbacks negativo";
  const emoji = isPositive ? "👍" : "👎";
  const borderColor = isPositive ? "#a2e8a2" : "#fa9292";
  return (
    <Box sx={{ ...style.statBox, borderColor }}>
      <Typography variant="h3" sx={{ fontWeight: "bold" }}>
        {amount}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: "500" }}>
        {emoji} {title}
      </Typography>
    </Box>
  );
}

const styles = () => ({
  statBox: {
    padding: "25px",
    justifyContent: "center",
    alignItems: "center",
    color: "#1e1a1a",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    border: "2px solid",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
});
