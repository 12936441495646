import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import lightTheme from "./themes/lightTheme";
import AppRouter from "./routers/AppRouter";
import { ApplicationProvider } from "./providers/applicationProvider";
import EducationAppBar from "./components/EducationAppBar";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ApplicationProvider>
      <ThemeProvider theme={lightTheme}>
        <EducationAppBar />
        <SnackbarProvider />
        <AppRouter />
      </ThemeProvider>
    </ApplicationProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
