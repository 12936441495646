import FilledButton from "./FilledButton";
import { Typography } from "@mui/material";

export default function FilledTextButton({ text, onClick, disabled = false }) {
  const style = styles();

  return (
    <FilledButton
      onClick={onClick}
      disabled={disabled}
      extraStyles={{ width: "fit-content" }}
    >
      <Typography color={disabled ? "grey" : "white"} sx={style.text}>
        {text}
      </Typography>
    </FilledButton>
  );
}

const styles = () => ({
  text: {
    fontSize: "14px",
  },
});
