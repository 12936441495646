import React, { useEffect, useState } from "react";
import ApiClient from "../../services/ApiClient";
import { fetchAndUpdateStateWithResult } from "../../utils";
import GeneratedExpansionDetail from "./GeneratedExpansionDetail";

function GeneratedEvaluationsDetail({ contentPlanning }) {
  const [contentPlanningEvaluations, setContentPlanningEvaluations] = useState(
    [],
  );

  const apiClient = new ApiClient();

  useEffect(() => {
    fetchAndUpdateStateWithResult(
      apiClient.evaluations(contentPlanning.id()),
      () => {},
      setContentPlanningEvaluations,
      [],
    );
  }, [contentPlanning]);

  const isContentPlanningDefined = () => {
    return contentPlanning !== undefined;
  };

  return (
    isContentPlanningDefined() && (
      <GeneratedExpansionDetail
        expansions={contentPlanningEvaluations}
        title="Evaluaciones generadas"
      />
    )
  );
}

export default GeneratedEvaluationsDetail;
