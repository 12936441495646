import React from "react";
import { Typography } from "@mui/material";
import FramedContainer from "../framed-container/FramedContainer";
import GeneratedContentPlanningActivities from "./GeneratedContentPlanningActivities";
import GeneratedContentPlanningEvaluations from "./GeneratedContentPlanningEvaluations";

function GeneratedContentPlanningExpandingOptions({
  generatedContentPlanning,
}) {
  const isContentPlanningGenerated = () => {
    return generatedContentPlanning !== undefined;
  };

  const renderSectionsToExpand = () => {
    if (isContentPlanningGenerated()) {
      return (
        <FramedContainer>
          <Typography variant="h6">
            Secciones expandibles de la planificación
          </Typography>

          <GeneratedContentPlanningActivities
            generatedContentPlanning={generatedContentPlanning}
          ></GeneratedContentPlanningActivities>
          <GeneratedContentPlanningEvaluations
            generatedContentPlanning={generatedContentPlanning}
          />
        </FramedContainer>
      );
    }
  };

  return isContentPlanningGenerated() ? renderSectionsToExpand() : undefined;
}

export default GeneratedContentPlanningExpandingOptions;
