import { useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";

export default function NotFoundScreen() {
  const theme = useTheme();
  const style = styles();
  const navigate = useNavigate();

  return (
    <section style={style.mainContainer}>
      <Typography variant="h6" fontWeight={"500"} color={theme.text.primary}>
        Pareces haberte perdido. Nos pasa a todos en algún momento.
      </Typography>
      <Button onClick={() => navigate("/")}>Volver</Button>
    </section>
  );
}

const styles = () => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    gap: 16,
  },
  image: {
    width: "900px",
  },
});
