import GeneratedContentPlanningAdapter from "../adapters/GeneratedContentPlanningAdapter";

export default class GeneratedContentPlanningResponse {
  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  contentPlanning() {
    return new GeneratedContentPlanningAdapter(this._jsonResponse).adapt();
  }
}
