import { Box, FormLabel, TextField } from "@mui/material";
import InfoIcon from "../InfoIcon";

function Text({
  id,
  label,
  value,
  onChange,
  type = "string",
  placeholder = "",
  disabled = false,
  minimumValue,
  required = false,
  fieldInfo,
}) {
  const inputProps = minimumValue !== undefined ? { min: minimumValue } : {};

  function renderInfoIconWithTooltip() {
    if (fieldInfo) {
      return <InfoIcon details={fieldInfo} />;
    }
  }

  return (
    <Box width="100%">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormLabel id={id} required={required}>
          {label}
        </FormLabel>
        {renderInfoIconWithTooltip()}
      </Box>
      <TextField
        aria-labelledby={id}
        variant="outlined"
        sx={{ width: "100%" }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        InputProps={{
          inputProps: inputProps,
        }}
      />
    </Box>
  );
}

export default Text;
