import { Box } from "@mui/material";
import errorToast from "../toasts/ErrorToast";
import { useState } from "react";
import TextArea from "../fields/TextArea";
import { useApplication } from "../../providers/applicationProvider";
import FilledTextButton from "../buttons/FilledTextButton";
import LoadableContent from "../LoadableContent";

export default function ContentPlanningFeedbackForRegenerationForm({
  contentPlanning,
  onContentPlanningRegenerated,
}) {
  const application = useApplication();
  const [feedback, setFeedback] = useState("");
  const [isRetrievingRegeneration, setIsRetrievingRegeneration] =
    useState(false);
  const style = styles();

  const regenerateFeedback = () => {
    setIsRetrievingRegeneration(true);
    application
      .apiClient()
      .regenerateContentPlanning(contentPlanning.id(), feedback)
      .then((response) => {
        const contentPlanning = response.contentPlanning();
        onContentPlanningRegenerated(contentPlanning);
      })
      .catch(() => {
        errorToast("Ocurrió un problema regenerar la planificación.");
      })
      .finally(() => {
        setIsRetrievingRegeneration(false);
      });
  };

  const isSubmitButtonDisabled = () => {
    return feedback === "";
  };

  const renderFeedbackForm = () => {
    return (
      <Box sx={style.mainContainer} component="form">
        <LoadableContent
          isLoading={isRetrievingRegeneration}
          text={"Regenerando planificación..."}
        >
          <TextArea
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder="Indicanos qué puede ser mejorado."
            disabled={false}
          />
          <FilledTextButton text={"regenerar"} onClick={regenerateFeedback} disabled={isSubmitButtonDisabled()}/>
        </LoadableContent>
      </Box>
    );
  };

  return contentPlanning ? renderFeedbackForm() : undefined;
}

const styles = () => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});
