import * as React from "react";

export default function IconButton({ icon, onClick, iconColor, disabled = false }) {
  const style = styles();
  const Icon = icon;

  return (
    <button onClick={onClick}
            disabled={disabled} style={style.container}>
      <Icon sx={{ color: iconColor }} />
    </button>
  );
}

const styles = () => ({
  container: {
    gap: "10px",
    border: "none",
    background: 'none'
  },
});
