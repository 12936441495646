import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import FramedContainer from "../framed-container/FramedContainer";
import { useState } from "react";
import Markdown from "../Markdown";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TabPanel from "../panels/TabPanel";
import ContentPlanningFeedbackForRegenerationForm from "../forms/ContentPlanningFeedbackForRegenerationForm";
import successToast from "../toasts/SuccessToast";
import { ContentPlanningPublicId } from "../buttons/ContentPlanningPublicId";
import FeedbackModal from "../modals/FeedbackModal";

function GeneratedContentPlanning({
  contentPlanning,
  readOnly,
  onContentPlanningChange,
}) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [wasFeedbackSubmitted, setWasFeedbackSubmitted] = useState(false);

  const isContentPlanningDefined = () => contentPlanning !== undefined;
  const style = styles();

  const renderFeedbackForm = () => {
    if (!readOnly) {
      return (
        <FeedbackModal
          contentPlanningId={contentPlanning.id()}
          onFeedbackSubmitted={() => {
            setWasFeedbackSubmitted(true);
          }}
          wasFeedbackSubmitted={wasFeedbackSubmitted}
        />
      );
    }
    return <></>;
  };

  const renderContextFiles = () => {
    if (contentPlanning.hasContextFiles()) {
      return (
        <List>
          {contentPlanning.contextFiles().map((source) => (
            <ListItem key={source}>
              <ListItemIcon>
                <TextSnippetIcon />
              </ListItemIcon>
              <ListItemText primary={source} />
            </ListItem>
          ))}
        </List>
      );
    }
  };

  const onContentPlanningRegenerated = (contentPlanning) => {
    onContentPlanningChange(contentPlanning);
    setWasFeedbackSubmitted(false);
    successToast("Planificación regenerada.");
  };

  const renderContentPlanning = () => (
    <FramedContainer styles={style.mainContainer}>
      {!readOnly && (
        <Typography variant="h4" fontWeight={"bold"}>
          {contentPlanning.title()}
        </Typography>
      )}
      <ContentPlanningPublicId publicId={contentPlanning.publicId()} />
      <Box sx={{ width: "100%" }}>
        <Markdown content={contentPlanning.content()}></Markdown>
      </Box>

      <Box sx={{ paddingTop: "20px" }}>
        <Tabs
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          aria-label="Detalle del contenido generado"
        >
          <Tab label="Documentos contexto" />
          {!readOnly && <Tab label="Regeneración" />}
        </Tabs>
      </Box>

      <TabPanel selectedIndex={selectedTab} tabIndex={0}>
        {renderContextFiles()}
      </TabPanel>
      {!readOnly && (
        <TabPanel selectedIndex={selectedTab} tabIndex={1}>
          <ContentPlanningFeedbackForRegenerationForm
            contentPlanning={contentPlanning}
            onContentPlanningRegenerated={onContentPlanningRegenerated}
          />
        </TabPanel>
      )}
      {renderFeedbackForm()}
    </FramedContainer>
  );

  return isContentPlanningDefined() ? renderContentPlanning() : undefined;
}

export default GeneratedContentPlanning;

const styles = () => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});
