export default class MetricsReportResponse {
  static defaultResponse() {
    return {
      content: {
        feedback: {
          feedbacks: [],
          amount_of_positive_feedbacks: 0,
          amount_of_negative_feedbacks: 0,
        },
        content_planning: {
          amount_of_content_planning: 34,
          amount_of_original_content_plannings: 10,
          amount_of_regenerated_content_plannings: 12,
          users_with_content_plannings: [
            {
              user_name: "Juan Perez",
              amount: 3,
            },
          ],
        },
      },
    };
  }

  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  amountOfNegativeFeedbacks() {
    return this._jsonResponse.content.feedback.amount_of_negative_feedbacks;
  }

  amountOfPositiveFeedbacks() {
    return this._jsonResponse.content.feedback.amount_of_positive_feedbacks;
  }

  feedbacks() {
    return this._jsonResponse.content.feedback.feedbacks;
  }

  amountOfContentPlannings() {
    return this._jsonResponse.content.content_planning
      .amount_of_content_planning;
  }

  amountOfOriginalContentPlannings() {
    return this._jsonResponse.content.content_planning
      .amount_of_original_content_plannings;
  }

  amountOfRegeneratedContentPlannings() {
    return this._jsonResponse.content.content_planning
      .amount_of_regenerated_content_plannings;
  }

  usersWithContentPlannings() {
    return this._jsonResponse.content.content_planning
      .users_with_content_plannings;
  }
}
