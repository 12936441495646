import ApiRequest from './ApiRequest';

class ApiRequester {
  constructor(application) {
    this.application = application;
    this.apiUrl = process.env.REACT_APP_API_URL;
  }

  post(path, body, withAuthorization, contentType = "application/json") {
    const bodyPayload = contentType === "multipart/form-data" ? body : JSON.stringify(body);
    return this._makeRequest("POST", path, withAuthorization, contentType, bodyPayload);
  }

  get(path, withAuthorization) {
    return this._makeRequest("GET", path, withAuthorization, "application/json");
  }

  _makeRequest(method, path, withAuthorization, contentType, bodyPayload = null) {
    const url = this.apiUrl + path;
    const request = new ApiRequest(url, method, withAuthorization, contentType, bodyPayload);

    return this._makeRequestOrRetry(request, 3);
  }

  _makeRequestOrRetry (request, retries) {
    const url = request.url();
    const data = {
      method: request.method(),
      headers: request.headers(),
      ...request.body()
    };

    return fetch(url, data).then((response) => {
      if (response.status === 403) {
        if(retries > 0) {
          return this._refreshUserTokenAndRetryRequest(request, retries);
        } else {
          this.application.logOut();
        }
      } else {
        return response.json();
      }
    });
  }

  _refreshUserTokenAndRetryRequest (request, retries) {
    return this.application.refreshUserToken().then(() => {
      return this._makeRequestOrRetry(request, retries - 1);
    });
  }
}

export default ApiRequester;
