import ApiRequester from "./ApiRequester";
import AppConfigurationResponse from "../communication/responses/AppConfigurationResponse";
import RegeneratedContentPlanningResponse from "../communication/responses/RegeneratedContentPlanningResponse";
import GeneratedContentPlanningResponse from "../communication/responses/GeneratedContentPlanningResponse";
import ContentPlanningHistoryResponse from "../communication/responses/ContentPlanningHistoryResponse";
import MetricsReportResponse from "../communication/responses/MetricsReportResponse";

class ApiClient {
  constructor(application) {
    this.apiRequester = new ApiRequester(application);
  }

  generateContentPlanning(parameters) {
    const body = {
      subject: parameters.subject(),
      grade: parameters.grade(),
      region: parameters.region(),
      instruction: parameters.instruction(),
      topic: parameters.topic(),
      title: parameters.title(),
      time_unit: parameters.extension(),
    };
    return this.apiRequester
      .post("/content-planning", body, true)
      .then((response) => new GeneratedContentPlanningResponse(response));
  }

  expandEvaluation(contentPlanning, contentPlanningId) {
    const body = { data: contentPlanning };
    return this.apiRequester.post(
      `/content-planning/${contentPlanningId}/evaluations/expand`,
      body,
      true,
    );
  }

  saveContentPlanningFeedback(feedback, contentPlanningId) {
    const body = {
      rate: feedback.rate(),
      detail: feedback.detail(),
    };
    return this.apiRequester.post(
      `/content-planning/${contentPlanningId}/feedback`,
      body,
      true,
    );
  }

  saveUser(user) {
    const body = {
      id: user.id(),
      full_name: user.fullName(),
      email: user.email(),
    };
    return this.apiRequester.post("/user", body, true);
  }

  listContextFiles() {
    return this.apiRequester.get("/context-files", true);
  }

  addFilesToContext(formData) {
    const headers = "multipart/form-data";
    return this.apiRequester.post("/upload-file", formData, true, headers);
  }

  expandActivities(contentPlanning, contentPlanningId) {
    const body = { data: contentPlanning };
    return this.apiRequester.post(
      `/content-planning/${contentPlanningId}/activities/expand`,
      body,
      true,
    );
  }

  contentPlanningHistory() {
    return this.apiRequester
      .get("/content-planning/history", true)
      .then((response) => new ContentPlanningHistoryResponse(response));
  }

  evaluations(contentPlanningId) {
    return this.apiRequester
      .get(`/content-planning/${contentPlanningId}/evaluations`, true)
      .then((response) => response.content);
  }

  activities(contentPlanningId) {
    return this.apiRequester
      .get(`/content-planning/${contentPlanningId}/activities`, true)
      .then((response) => response.content);
  }

  feedback(contentPlanningId) {
    return this.apiRequester
      .get(`/content-planning/${contentPlanningId}/feedback`, true)
      .then((response) => response.content);
  }

  appConfiguration() {
    return this.apiRequester
      .get(`/app/configuration`, true)
      .then((response) => new AppConfigurationResponse(response));
  }

  regenerateContentPlanning(contentPlanningId, feedback) {
    return this.apiRequester
      .post(
        `/content-planning/${contentPlanningId}/regenerate`,
        { feedback },
        true,
      )
      .then((response) => new RegeneratedContentPlanningResponse(response));
  }

  metricsReport() {
    return this.apiRequester
      .get(`/metrics`, true)
      .then((response) => new MetricsReportResponse(response));
  }
}

export default ApiClient;
