import { AuthStorage } from '../storage/authStorage';

export default class ApiRequest {
  constructor(url, method, withAuthorization, contentType, bodyPayload) {
    this._url = url;
    this._method = method;
    this._withAuthorization = withAuthorization;
    this._contentType = contentType;
    this._bodyPayload = bodyPayload;
    this._authStorage = new AuthStorage();
  }

  url() {
    return this._url;
  }

  method() {
    return this._method;
  }

  headers() {
    let headers = {};
    if (this._contentType !== "multipart/form-data") {
      headers = { "Content-Type": this._contentType };
    }
    if (this._withAuthorization) {
      headers["Authorization"] = `Bearer ${this._authStorage.getUserToken()}`;
    }
    return new Headers(headers);
  }

  body() {
    return this._bodyPayload ? { body: this._bodyPayload } : {};
  }
}