import React from 'react';
import Application from '../app/Application';
import { AuthStorage } from '../storage/authStorage';
import { FirebaseAuthentication } from '../app/FirebaseAuthentication';

export const ApplicationContext = React.createContext(null);

export const ApplicationProvider = ({ children }) => {
  const authStorage = new AuthStorage();
  const authentication = new FirebaseAuthentication(authStorage);
  const application = new Application(authentication);

  application.load();

  return (
    <ApplicationContext.Provider value={application}>
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplication = () => React.useContext(ApplicationContext);