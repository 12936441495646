import GeneratedContentPlanningsAdapter from "../adapters/GeneratedContentPlanningsAdapter";

export default class ContentPlanningHistoryResponse {
  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  contentPlannings() {
    return new GeneratedContentPlanningsAdapter(this._jsonResponse).adapt();
  }
}
