import { Box, InputLabel, MenuItem, Select as MuiSelect } from "@mui/material";
import InfoIcon from "../InfoIcon";

function Select({
  id,
  label,
  placeholder,
  value,
  options,
  onChange,
  required = false,
  fieldInfo,
}) {
  return (
    <Box width="100%">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <InputLabel id={id} required={required}>
          {label}
        </InputLabel>

        {fieldInfo && <InfoIcon details={fieldInfo} />}
      </Box>

      <MuiSelect
        displayEmpty
        labelId={id}
        id={`${label}-options`}
        value={value}
        fullWidth
        variant="outlined"
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem value={option} key={option} onClick={() => onChange(option)}>
            {option}
          </MenuItem>
        ))}
      </MuiSelect>
    </Box>
  );
}

export default Select;
