import React, { useEffect, useState } from "react";
import ApiClient from "../../services/ApiClient";
import { fetchAndUpdateStateWithResult } from "../../utils";
import GeneratedExpansionDetail from "./GeneratedExpansionDetail";

function GeneratedActivitiesDetail({ contentPlanning }) {
  const [contentPlanningActivities, setContentPlanningActivities] = useState(
    [],
  );

  const apiClient = new ApiClient();

  useEffect(() => {
    fetchAndUpdateStateWithResult(
      apiClient.activities(contentPlanning.id()),
      () => {},
      setContentPlanningActivities,
      [],
    );
  }, [contentPlanning]);

  const isContentPlanningDefined = () => {
    return contentPlanning !== undefined;
  };

  return (
    isContentPlanningDefined() && (
      <GeneratedExpansionDetail
        expansions={contentPlanningActivities}
        title="Actividades generadas"
      />
    )
  );
}

export default GeneratedActivitiesDetail;
