import ApiClient from '../services/ApiClient';
import errorToast from '../components/toasts/ErrorToast';
import UnauthenticatedUser from './UnauthenticatedUser';
import { action, computed, makeObservable, observable } from 'mobx';

export default class Application {
  constructor(authentication) {
    this._apiClient = new ApiClient(this);
    this._currentVersion = "";
    this._authentication = authentication;
    this._currentUser = new UnauthenticatedUser();

    makeObservable(this, {
      _currentVersion: observable,
      _currentUser: observable,
      _updateCurrentUser: action,
      currentVersion: computed,
      load: action,
    });
  }

  async _updateAppConfiguration() {
    const response = await this._apiClient.appConfiguration();
    this._currentVersion = response.configuration().version();
  }

  load() {
    const user = this._authentication.getCurrentUser();

    this._updateCurrentUser(user);
    this._updateAppConfiguration();
  }

  apiClient() {
    return this._apiClient;
  }

  get currentVersion() {
    return this._currentVersion;
  }

  logIn = (userCredentials) => {
    const user = this._authentication.createUserFromCredentials(userCredentials);

    this.apiClient()
      .saveUser(user)
      .then(() => {
        this._authentication.login(user);
        this._updateCurrentUser(user);
      })
      .catch(() => {
        errorToast("Ocurrió un error al crear un usuario.");
      });
  };

  logOut = () => {
    this._authentication.logout();
    this._updateCurrentUser(new UnauthenticatedUser());
  };

  async refreshUserToken() {
    return this._authentication.refreshUserToken();
  }

  get userIsAuthenticated() {
    return this._currentUser.isAuthenticated();
  }

  authentication() {
    return this._authentication.app();
  }

  _updateCurrentUser(user) {
    this._currentUser = user;
  }
}
