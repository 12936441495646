import { IconButton, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

function InfoIcon({ details }) {
  return (
    <Tooltip title={details} arrow placement={"right"}>
      <IconButton sx={{ cursor: "default" }}>
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  );
}

export default InfoIcon;
