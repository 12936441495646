import { Box } from "@mui/material";

function TabPanel({ children, selectedIndex, tabIndex }) {
  return (
    <div
      role="tabpanel"
      hidden={selectedIndex !== tabIndex}
      id={`tab-panel-${tabIndex}`}
      aria-labelledby={`tab-${tabIndex}`}
    >
      {selectedIndex === tabIndex && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default TabPanel;
