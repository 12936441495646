import FirebaseUIReact from 'firebaseui-react';
import errorToast from '../components/toasts/ErrorToast';
import FramedContainer from '../components/framed-container/FramedContainer';
import { Box, Typography } from '@mui/material';
import { useApplication } from '../providers/applicationProvider';

export default function LoginScreen() {
  const application = useApplication();

  const configuration = {
    signInFlow: "popup",
    signInOptions: ["google.com"],
    callbacks: {
      signInSuccessWithAuthResult: application.logIn,
      signInFailure: () => {
        application.logOut();
        errorToast("Ocurrió un error al iniciar sesión.");
      },
    },
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <FramedContainer widthSize="xs">
        <Typography component="h4" variant="h6">
          ¡Bienvenido!
        </Typography>
        <Typography component="h4" variant="h6">
          Ingresá con tu cuenta para continuar.
        </Typography>

        <Box sx={{ marginTop: "15px" }}>
          <FirebaseUIReact auth={application.authentication()} config={configuration}></FirebaseUIReact>
        </Box>
      </FramedContainer>
    </Box>
  );
}
