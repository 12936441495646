import GeneratedContentPlanningAdapter from "../adapters/GeneratedContentPlanningAdapter";

export default class RegeneratedContentPlanningResponse {
  static defaultResponse() {
    return {
      content_planning: {
        content_planning_id: "asdf123123",
        content: "Some content",
      },
    };
  }

  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  contentPlanning() {
    return new GeneratedContentPlanningAdapter(this._jsonResponse).adapt();
  }
}
