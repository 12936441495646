import ButtonDialog from "./common/ButtonDialog";
import ContentPlanningFeedbackForm from "../forms/ContentPlanningFeedbackForm";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import {Box} from "@mui/material";

export default function FeedbackModal({
  contentPlanningId,
  wasFeedbackSubmitted,
  onFeedbackSubmitted,
}) {
  return (
    <Box>
      <ButtonDialog icon={ThumbUpIcon} iconColor={"grey"}>
        <ContentPlanningFeedbackForm
          onFeedbackSubmitted={onFeedbackSubmitted}
          contentPlanningId={contentPlanningId}
          isFeedbackSubmitted={wasFeedbackSubmitted}
          wasFeedbackUseful={true}
        />
      </ButtonDialog>
      <ButtonDialog icon={ThumbDownIcon} iconColor={"grey"}>
        <ContentPlanningFeedbackForm
          onFeedbackSubmitted={onFeedbackSubmitted}
          isFeedbackSubmitted={wasFeedbackSubmitted}
          contentPlanningId={contentPlanningId}
          wasFeedbackUseful={false}
        />
      </ButtonDialog>
    </Box>
  );
}
