import { Box, Typography } from "@mui/material";
import { useApplication } from "../providers/applicationProvider";
import { useEffect, useState } from "react";
import LoadableContent from "../components/LoadableContent";
import FeedbackStatBox from "../components/metrics/FeedbackStatBox";
import StatsBox from "../components/metrics/StatsBox";
import FeedbacksList from "../components/metrics/FeedbacksList";

export default function MetricsScreen() {
  const application = useApplication();
  const [metricsReport, setMetricsReport] = useState(undefined);

  useEffect(() => {
    application
      .apiClient()
      .metricsReport()
      .then((response) => {
        setMetricsReport(response);
      });
  }, []);

  const style = styles();

  const contentPlanningBox = () => {
    return (
      <StatsBox title={"Cantidad de planificaciones"}>
        <Typography variant="body1" fontWeight={"bold"}>
          Totales: {metricsReport?.amountOfContentPlannings()}
        </Typography>
        <Typography variant="body1" fontWeight={"bold"}>
          Originales: {metricsReport?.amountOfOriginalContentPlannings()}
        </Typography>
        <Typography variant="body1" fontWeight={"bold"}>
          Regeneradas: {metricsReport?.amountOfRegeneratedContentPlannings()}
        </Typography>
      </StatsBox>
    );
  };

  const renderSubtitle = (name) => {
    return (
      <Typography variant="h5" fontWeight={"bold"}>
        {name}
      </Typography>
    );
  };

  const renderFeedbackSection = () => {
    const feedbacks = metricsReport?.feedbacks();
    return (
      <Box sx={{ marginTop: "80px" }}>
        {renderSubtitle("Feedbacks")}
        <Box sx={{ display: "flex", gap: "20px", maxHeight: "500px" }}>
          <FeedbacksList feedbacks={feedbacks} />
          <Box sx={style.statsContainer}>
            <FeedbackStatBox
              isPositive={true}
              amount={metricsReport?.amountOfPositiveFeedbacks()}
            />
            <FeedbackStatBox
              isPositive={false}
              amount={metricsReport?.amountOfNegativeFeedbacks()}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const renderContentPlanningSection = () => {
    return (
      <>
        {renderSubtitle("Planificaciones")}
        <Box sx={{ display: "flex", gap: "15px" }}>
          {contentPlanningBox()}
          <StatsBox title={"Planificaciones por usuario:"}>
            {metricsReport?.usersWithContentPlannings().map((user) => (
              <Typography
                variant="body1"
                fontWeight={"bold"}
                key={user.user_name}
                sx={{
                  borderBottom: "darkgrey solid 1px",
                  padding: "5px",
                  color: "#3c3434",
                }}
              >
                {user.user_name}: {user.amount}
              </Typography>
            ))}
          </StatsBox>
        </Box>
      </>
    );
  };

  return (
    <Box sx={style.mainContainer}>
      <LoadableContent
        isLoading={metricsReport === undefined}
        text={"Obteniendo métricas."}
      >
        {renderFeedbackSection()}
        {renderContentPlanningSection()}
      </LoadableContent>
    </Box>
  );
}

const styles = () => ({
  mainContainer: {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    background: "#f7f7f7",
    minHeight: "calc(100vh - 64px)",
    gap: "25px",
  },
  statsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    width: "300px",
  },
});
