import { LocalStorage } from "./localStorage";

export class AuthStorage {
  TOKEN = "token";
  EMAIL = "email";
  FULL_NAME = "fullName";

  constructor() {
    this.localStorage = new LocalStorage();
  }

  getCurrentUser() {
    const userToken = this.localStorage.get(this.TOKEN);

    return userToken ? {
      email: this.localStorage.get(this.EMAIL),
      token: userToken,
      fullName: this.localStorage.get(this.FULL_NAME),
    } : null;
  }

  saveUser(user) {
    this.localStorage.add(this.EMAIL, user.email());
    this.localStorage.add(this.TOKEN, user.token());
    this.localStorage.add(this.FULL_NAME, user.fullName());
  }

  removeUser() {
    this.localStorage.remove(this.EMAIL);
    this.localStorage.remove(this.TOKEN);
    this.localStorage.remove(this.FULL_NAME);
  }

  getUserToken() {
    return this.localStorage.get(this.TOKEN);
  }

  updateToken(token) {
    this.localStorage.add(this.TOKEN, token);
  }
}
