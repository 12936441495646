import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

function TitleWithActions({ title, children }) {
  return (
    <Box sx={{ display: "inline-block", width: "100%", position: "sticky" }}>
      <Typography sx={{ display: "inline-block" }} variant="h4">
        {title}
      </Typography>

        <Box sx={{ display: "inline-block", float: "right" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default TitleWithActions;
