import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import FramedContainer from "../framed-container/FramedContainer";
import FilledTextButton from "../buttons/FilledTextButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Markdown from "../Markdown";
import { useApplication } from "../../providers/applicationProvider";

function GeneratedContentPlanningEvaluations({ generatedContentPlanning }) {
  const [isExpandingEvaluation, setExpandingEvaluation] = useState(false);
  const [evaluations, setEvaluations] = useState();

  const application = useApplication();

  const expandEvaluation = () => {
    setExpandingEvaluation(true);
    application
      .apiClient()
      .expandEvaluation(
        generatedContentPlanning.content(),
        generatedContentPlanning.id(),
      )
      .then((response) => {
        setExpandingEvaluation(false);
        setEvaluations(response.content);
      })
      .catch(() => {
        setExpandingEvaluation(false);
        setEvaluations(undefined);
      });
  };

  const renderEvaluationsDetail = () => {
    if (evaluations) {
      return (
        <FramedContainer>
          <Box sx={{ width: "100%" }}>
            <Markdown content={evaluations}></Markdown>
          </Box>
        </FramedContainer>
      );
    }
  };

  const isContentPlanningGenerated = () => {
    return generatedContentPlanning !== undefined;
  };

  const renderEvaluations = () => {
    if (isContentPlanningGenerated()) {
      const btnText = isExpandingEvaluation ? "Creando.." : "Crear";
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography component="span">Evaluaciones</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FilledTextButton
              onClick={expandEvaluation}
              disabled={isExpandingEvaluation}
              text={btnText}
            />
            {renderEvaluationsDetail()}
          </AccordionDetails>
        </Accordion>
      );
    }
  };

  return isContentPlanningGenerated() ? renderEvaluations() : undefined;
}

export default GeneratedContentPlanningEvaluations;
