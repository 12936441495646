import "./FramedContainer.css";
import { Container } from "@mui/material";

function FramedContainer({ children, styles }) {
  return (
    <Container className="framed-container" disableGutters sx={styles}>
      {children}
    </Container>
  );
}

export default FramedContainer;
