import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import User from './User';
import UnauthenticatedUser from './UnauthenticatedUser';

export class FirebaseAuthentication {
  constructor(authStorage) {
    const firebaseConfiguration = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
    };

    initializeApp(firebaseConfiguration);
    this.authStorage = authStorage;
    this.auth = getAuth();
  }

  getCurrentUser() {
    const serializedUser = this.authStorage.getCurrentUser();

    if (!serializedUser) {
      return new UnauthenticatedUser();
    }

    // TODO
    // We cannot get the user id from auth storage, refactor this. Should use only firebase to keep track of the user
    // authentication state instead of persisting it in local storage. Another way would be to remove id from User
    // but it's still used at login.
    return new User('', serializedUser.token, serializedUser.fullName, serializedUser.email);
  }

  createUserFromCredentials(userCredentials) {
    return new User(
      userCredentials.user.uid,
      userCredentials.user.accessToken,
      userCredentials.user.displayName,
      userCredentials.user.email,
    );
  }

  login(user) {
    this.authStorage.saveUser(user);
  }

  logout() {
    this.authStorage.removeUser();
  }

  app() {
    return this.auth;
  }

  async refreshUserToken() {
    const token = await this.auth.currentUser.getIdToken(true);
    this.authStorage.updateToken(token);
  }
}
