export default class ContentPlanningParameters {
  constructor(subject, grade, region, instruction, topic, timeUnit, title) {
    this._subject = subject;
    this._grade = grade;
    this._region = region;
    this._instruction = instruction;
    this._topic = topic;
    this._timeUnit = timeUnit;
    this._title = title;
  }

  extension() {
    return this._timeUnit;
  }

  topic() {
    return this._topic;
  }

  instruction() {
    return this._instruction;
  }

  region() {
    return this._region;
  }

  grade() {
    return this._grade;
  }

  subject() {
    return this._subject;
  }

  title() {
    return this._title;
  }
}
