export default class GeneratedContentPlanning {
  constructor(
    id,
    grade,
    instruction,
    region,
    subject,
    content,
    date,
    topic,
    timeUnit,
    appVersion,
    contextFiles,
    publicId,
    title,
  ) {
    this._id = id;
    this._grade = grade;
    this._instruction = instruction;
    this._region = region;
    this._subject = subject;
    this._content = content;
    this._creationDate = date;
    this._topic = topic;
    this._timeUnit = timeUnit;
    this._appVersion = appVersion;
    this._contextFiles = contextFiles;
    this._publicId = publicId;
    this._title = title;
  }

  id() {
    return this._id;
  }

  publicId() {
    return this._publicId;
  }

  content() {
    return this._content;
  }

  subject() {
    return this._subject;
  }

  region() {
    return this._region;
  }

  instruction() {
    return this._instruction;
  }

  grade() {
    return this._grade;
  }

  creationDate() {
    return this._creationDate;
  }

  topic() {
    return this._topic;
  }

  timeUnit() {
    return this._timeUnit;
  }

  appVersion() {
    return this._appVersion;
  }

  contextFiles() {
    return this._contextFiles;
  }

  hasContextFiles() {
    return !!this.contextFiles();
  }

  title() {
    return this._title;
  }
}
