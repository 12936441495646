import {Box, Typography} from "@mui/material";

export default function StatsBox({title, children}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        background: "white",
        padding: "25px",
        width: "300px",
      }}
    >
      <Typography variant="h6" fontWeight={"bold"}>
        {title}
      </Typography>
      {children}
    </Box>
  );
}