import { Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ApiClient from "../../services/ApiClient";
import { fetchAndUpdateStateWithResult } from "../../utils";
import ParameterDetail from "./ParameterDetail";

function GeneratedFeedbackDetail({ contentPlanning }) {
  const [isFetchingFeedback, setFetchingFeedback] = useState(false);
  const [contentPlanningFeedback, setContentPlanningFeedback] = useState();

  const apiClient = new ApiClient();

  useEffect(() => {
    fetchAndUpdateStateWithResult(apiClient.feedback(contentPlanning.id()), setFetchingFeedback, setContentPlanningFeedback, undefined);
  }, [contentPlanning]);

  const isContentPlanningDefined = () => {
    return contentPlanning !== undefined;
  };

  return (
    isContentPlanningDefined() &&
    contentPlanningFeedback && (
      <>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6" gutterBottom>
          Feedback:
        </Typography>
        <ParameterDetail title="Valoración" description={contentPlanningFeedback.rate} />
        <ParameterDetail title="Detalle" description={contentPlanningFeedback.detail} />
      </>
    )
  );
}

export default GeneratedFeedbackDetail;
