export default class User {
  constructor(id, token, fullName, email) {
    this._id = id;
    this._token = token;
    this._fullName = fullName;
    this._email = email;
  }

  id() {
    return this._id;
  }

  fullName() {
    return this._fullName;
  }

  email() {
    return this._email;
  }

  token() {
    return this._token;
  }

  isAuthenticated() {
    return true;
  }
}
