import { Box, Divider, List, ListItem, ListItemButton, ListItemText, Typography, } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApplication } from '../providers/applicationProvider';
import GeneratedContentPlanning from '../components/generated-content-planning/GeneratedContentPlanning';
import DrawerPanel from '../components/panels/DrawerPanel';
import TitleWithActions from '../components/TitleWithActions';
import LoadableContent from '../components/LoadableContent';
import ParameterDetail from '../components/content-planning-history/ParameterDetail';
import GeneratedEvaluationsDetail from '../components/content-planning-history/GeneratedEvaluationsDetail';
import GeneratedActivitiesDetail from '../components/content-planning-history/GeneratedActivitiesDetail';
import GeneratedFeedbackDetail from '../components/content-planning-history/GeneratedFeedbackDetail';
import PdfExportableContainer from '../components/PdfExportableContainer';

function ContentPlanningHistory() {
  const [selectedContentPlanning, setSelectedContentPlanning] =
    useState(undefined);
  const [isFetchingHistory, setFetchingHistory] = useState(true);
  const [contentPlanningHistory, setContentPlanningHistory] = useState([]);

  const application = useApplication();
  const navigate = useNavigate();
  const apiClient = application.apiClient();
  const style = styles();

  useEffect(() => {
    const getContentPlanningHistory = () => {
      setFetchingHistory(true);
      apiClient
        .contentPlanningHistory()
        .then((response) => {
          setFetchingHistory(false);
          setContentPlanningHistory(response.contentPlannings());
        })
        .catch(() => {
          setFetchingHistory(false);
          setContentPlanningHistory([]);
        });
    };

    getContentPlanningHistory();
  }, [navigate, apiClient]);

  const hasSelectedContentPlanning = () => {
    return selectedContentPlanning !== undefined;
  };

  const selectedContentPlanningId = () => {
    return hasSelectedContentPlanning()
      ? selectedContentPlanning.id()
      : undefined;
  };

  const renderContentPlanningList = () => {
    return (
      <Box sx={{ display: "flex" }}>
        <List
          sx={{
            width: "300px",
            flexShrink: 0,
            borderRight: 1,
            borderColor: "divider",
            overflowY: "scroll",
          }}
        >
          {contentPlanningHistory.map((contentPlanning) => {
            const id = contentPlanning.id();
            const title = contentPlanning.subject();
            const publicId = contentPlanning.publicId().toUpperCase();
            const subTitle = contentPlanning.creationDate();
            return (
              <ListItem key={id} disablePadding>
                <ListItemButton
                  selected={selectedContentPlanningId() === id}
                  onClick={() => setSelectedContentPlanning(contentPlanning)}
                >
                  {publicId ?
                    <ListItemText primary={`${publicId} - ${title}`} secondary={subTitle} /> :
                    <ListItemText primary={title} secondary={subTitle} />
                  }
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    );
  };

  const renderSelectedContentPlanning = () => {
    if (hasSelectedContentPlanning()) {
      return (
        <Box sx={{ flexGrow: 1, overflowY: "scroll" }}>
          <PdfExportableContainer
            buttonTitle="Descargar PDF"
            fileName="planificacion.pdf"
          >
            <TitleWithActions title={selectedContentPlanning.title() || selectedContentPlanning.subject()}>
              <Typography variant="body1" sx={style.tagVersionContainer}>
                v <b>{selectedContentPlanning.appVersion()}</b>
              </Typography>
            </TitleWithActions>
            <Typography variant="subtitle1" color="text.secondary" gutterBottom>
              Generada el: {selectedContentPlanning.creationDate()}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Parámetros:
            </Typography>
            <ParameterDetail
              title="Región"
              description={selectedContentPlanning.region()}
            />
            <ParameterDetail
              title="Grado"
              description={selectedContentPlanning.grade()}
            />
            <ParameterDetail
              title="Tema"
              description={selectedContentPlanning.topic()}
            />
            <ParameterDetail
              title="Extensión"
              description={selectedContentPlanning.timeUnit()}
            />
            <ParameterDetail
              title="Instrucción"
              description={selectedContentPlanning.instruction()}
            />
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" gutterBottom>
              Contenido generado:
            </Typography>
            <GeneratedContentPlanning
              contentPlanning={selectedContentPlanning}
              readOnly
            />
            <GeneratedEvaluationsDetail
              contentPlanning={selectedContentPlanning}
            />
            <GeneratedActivitiesDetail
              contentPlanning={selectedContentPlanning}
            />
            <GeneratedFeedbackDetail
              contentPlanning={selectedContentPlanning}
            />
          </PdfExportableContainer>
        </Box>
      );
    }
  };

  return (
    <DrawerPanel>
      <TitleWithActions title="Historial" />
      <LoadableContent isLoading={isFetchingHistory}>
        <Box sx={style.containerBox}>
          {renderContentPlanningList()}
          {renderSelectedContentPlanning()}
        </Box>
      </LoadableContent>
    </DrawerPanel>
  );
}

export default ContentPlanningHistory;

const styles = () => ({
  containerBox: {
    display: "flex",
    flexDirection: "row",
    height: "calc(100vh - 150px)",
  },
  tagVersionContainer: {
    background: " #5555f4",
    color: "white",
    padding: "2px 8px",
    borderRadius: "5px",
  },
});
