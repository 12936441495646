import { AppBar, Toolbar, Typography } from "@mui/material";
import AppVersion from "./AppVersion";
import React from "react";
import { useApplication } from "../providers/applicationProvider";
import { observer } from 'mobx-react';

function EducationAppBar() {
  const application = useApplication();
  const style = styles();

  return (
    <AppBar
      position="fixed"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar sx={style.toolbarContainer}>
        <Typography variant="h6" noWrap component="div" color={'white'}>
          Educación Argentina
        </Typography>
        <AppVersion version={application.currentVersion} />
      </Toolbar>
    </AppBar>
  );
}

export default observer(EducationAppBar);

const styles = () => ({
  toolbarContainer: {
    display: "flex",
    justifyContent: "space-between",
  }
});
