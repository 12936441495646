import { Box, Typography } from "@mui/material";

export default function FeedbacksList({ feedbacks }) {
  const style = styles();

  const renderFeedbackBox = (feedback) => {
    const borderColor = feedback.was_positive ? "#a2e8a2" : "#fa9292";
    return (
      <Box
        sx={{ ...style.feedbackContainer, borderColor }}
        key={feedback.content_planning_id}
      >
        <Typography variant="body1">
          {" "}
          <b>Detalles:</b> {feedback.detail}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={style.feedbacksContainer}>
      {feedbacks.map((feedback) => renderFeedbackBox(feedback))}
    </Box>
  );
}

const styles = () => ({
  feedbackContainer: {
    borderRadius: "5px",
    padding: "10px",
    border: "2px solid",
    background: "white",
  },
  feedbacksContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    overflowY: "scroll",
  },
});
