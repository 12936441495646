import './App.css';
import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import ContentPlanning from './screens/ContentPlanning';
import ContentArea from './components/ContentArea';
import ContextDetail from './screens/ContextDetail';
import ContentPlanningHistory from './screens/ContentPlanningHistory';
import { useApplication } from './providers/applicationProvider';

function App () {
  const application = useApplication();
  const DRAWER_ITEMS = {
    CONTENT_PLANNING: 'Planificación',
    CONTENT_PLANNING_HISTORY: 'Historial de planificaciones',
    CONTEXT: 'Contexto',
    LOG_OUT: 'Cerrar sesión',
  };

  const [selectedItem, setSelectedItem] = useState(
    DRAWER_ITEMS.CONTENT_PLANNING,
  );

  const renderDrawerItem = (text, icon, onClick, sendToBottom) => {
    const isSelected = selectedItem === text;
    return (
      <ListItem
        key={text}
        disablePadding
        selected={isSelected}
        onClick={() => {setSelectedItem(text); onClick?.();}}

        sx={{ display: 'block', marginTop: sendToBottom ? 'auto' : 0 }}
      >
        <ListItemButton selected={isSelected}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text}/>
        </ListItemButton>
      </ListItem>
    );
  };

  const renderSelectedContent = () => {
    switch (selectedItem) {
      case DRAWER_ITEMS.CONTEXT:
        return <ContextDetail/>;
      case DRAWER_ITEMS.CONTENT_PLANNING_HISTORY:
        return <ContentPlanningHistory/>;
      case DRAWER_ITEMS.CONTENT_PLANNING:
      default:
        return <ContentPlanning/>;
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
        }}
      >
        <Toolbar/>
        <List sx={{flex: 1, display: 'flex', flexDirection: 'column', height: '100%'}}>
          {renderDrawerItem(DRAWER_ITEMS.CONTEXT, <ArticleIcon/>)}
          {renderDrawerItem(DRAWER_ITEMS.CONTENT_PLANNING, <EventNoteIcon/>)}
          {renderDrawerItem(
            DRAWER_ITEMS.CONTENT_PLANNING_HISTORY,
            <HistoryIcon/>,
          )}
          {renderDrawerItem(DRAWER_ITEMS.LOG_OUT, <LogoutIcon/>, application.logOut, true)}
        </List>
      </Drawer>
      <ContentArea>{renderSelectedContent()}</ContentArea>
    </Box>
  );
}

export default App;
