import FilledButton from "./FilledButton";
import { Typography } from "@mui/material";
import * as React from "react";

export default function FilledIconTextButton({
  icon,
  text,
  onClick,
  disabled = false,
}) {
  const style = styles();
  const Icon = icon;

  return (
    <FilledButton
      color="primary"
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      extraStyles={{gap: '10px'}}
    >
      <Icon sx={{ color: "white" }} />
      <Typography color={disabled ? "grey" : "white"} sx={style.text}>
        {text}
      </Typography>
    </FilledButton>
  );
}

const styles = () => ({
  text: {
    fontSize: "14px",
  },
});
