import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { Alert, Link, ListItem, ListItemText, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import successToast from "../components/toasts/SuccessToast";
import errorToast from "../components/toasts/ErrorToast";
import DrawerPanel from "../components/panels/DrawerPanel";
import TitleWithActions from "../components/TitleWithActions";
import LoadableContent from "../components/LoadableContent";
import { useApplication } from "../providers/applicationProvider";
import FilledTextButton from "../components/buttons/FilledTextButton";
import FilledIconTextButton from "../components/buttons/FilledIconTextButton";

function ContextDetail() {
  const [contextFiles, setContextFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [isUploadingFile, setFileUploading] = useState(false);
  const [isFetchingContextFiles, setFetchingContextFiles] = useState(true);

  const application = useApplication();
  const apiClient = application.apiClient();

  const navigate = useNavigate();
  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
  useEffect(() => {
    const getContextFiles = () => {
      setFetchingContextFiles(true);
      apiClient
        .listContextFiles()
        .then(({ content }) => {
          setFetchingContextFiles(false);
          setContextFiles(content);
        })
        .catch(() => {
          setFetchingContextFiles(false);
          setContextFiles([]);
        });
    };
    getContextFiles();
  }, [navigate]);

  // TODO: Think how can we manage this style out of here and reuse our FilledButton so as to not have repeated code
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const renderFetchingError = () => {
    return (
      <Alert severity="error" sx={{ marginTop: "15px" }}>
        No pudimos obtener los documentos.
      </Alert>
    );
  };

  const renderFileList = () => {
    return (
      <List>
        {contextFiles.map((file, index) => (
          <ListItem key={index} sx={{ padding: "0" }}>
            <ListItemText
              primary={
                <Link
                  href={file.url}
                  color="primary"
                  underline="hover"
                  target={"_blank"}
                >
                  {file.name}
                </Link>
              }
            />
          </ListItem>
        ))}
      </List>
    );
  };

  const renderFileLinks = () => {
    return (
      <LoadableContent isLoading={isFetchingContextFiles}>
        {contextFiles.length === 0 ? renderFetchingError() : renderFileList()}
      </LoadableContent>
    );
  };

  const handleFileSubmission = async (event) => {
    event.preventDefault();
    if (!file) {
      errorToast("Seleccione un archivo para subir al sistema");
      return;
    }

    setFileUploading(true);
    const formData = new FormData();
    formData.append("file", file);

    apiClient.addFilesToContext(formData).then((response) => {
      setFileUploading(false);
      if (response.error) {
        errorToast("El archivo no pudo ser cargado");
      } else {
        successToast("El archivo se cargó exitosamente");
      }
    });
  };

  const handleButtonClick = () => {
    document.getElementById("file-input").click();
  };

  const isFileUploadDisabled = () => {
    return !file || isUploadingFile;
  };

  return (
    <DrawerPanel>
      <TitleWithActions title="Contexto">
        <FilledIconTextButton
          text={"Seleccionar archivo"}
          onClick={handleButtonClick}
          icon={CloudUploadIcon}
        />

        <VisuallyHiddenInput
          id="file-input"
          type="file"
          onChange={handleFileChange}
          accept=".pdf"
        />

        <FilledTextButton
          onClick={handleFileSubmission}
          disabled={isFileUploadDisabled()}
          text={isUploadingFile ? "Cargando archivo...": "Cargar archivo"}
        />
      </TitleWithActions>

      <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
        Documentación utilizada como contexto para generar las respuestas.
      </Typography>

      <Box
        sx={{
          flex: "1",
          overflowY: "scroll",
        }}
      >
        {renderFileLinks()}
      </Box>
    </DrawerPanel>
  );
}

export default ContextDetail;
