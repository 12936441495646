import { AppConfiguration } from "../../domain/AppConfiguration";

export default class AppConfigurationAdapter {
  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  adapt() {
    return new AppConfiguration(this._jsonResponse.app_configuration.version);
  }
}
