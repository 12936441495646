import { Typography } from "@mui/material";
import React from "react";

function ParameterDetail({title, description}) {
  return (
    <Typography variant="body1" component="p">
      <strong>{title}:</strong> {description}
    </Typography>
  );
}

export default ParameterDetail;
