import { useRef } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { jsPDF } from "jspdf";

function PdfExportableContainer({ buttonTitle, fileName, children }) {
  const pdfRef = useRef();
  const downloadPDF = () => {
    const content = pdfRef.current;

    const document = new jsPDF("p", "pt", "a4");
    document.html(content, {
      callback: function (doc) {
        doc.save(fileName);
      },
      margin: [20, 20, 20, 20],
      autoPaging: "text",
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: 0.4,
      },
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={downloadPDF} endIcon={<DownloadIcon />}>
          {buttonTitle}
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, p: 2 }} ref={pdfRef}>
        {children}
      </Box>
    </>
  );
}

export default PdfExportableContainer;
