import AppConfigurationAdapter from "../adapters/AppConfigurationAdapter";

export default class AppConfigurationResponse {
  static defaultResponse() {
    return {
      app_configuration: {
        version: "1.0.0",
      }
    };
  }

  constructor(jsonResponse) {
    this._jsonResponse = jsonResponse;
  }

  configuration() {
    return new AppConfigurationAdapter(this._jsonResponse).adapt();
  }

}