import { Button } from "@mui/material";
import { copyToClipboard } from "../../utils";
import successToast from "../toasts/SuccessToast";
import errorToast from "../toasts/ErrorToast";
import React from "react";

export function ContentPlanningPublicId({ publicId }) {
  const style = styles();
  return (
    <Button
      sx={style.contentPlanningPublicIdContainer}
      onClick={() =>
        copyToClipboard(
          publicId,
          () => successToast("Id copiado"),
          () => errorToast("No se pudo copiar el id."),
        )
      }
    >
      id: <b>{publicId}</b>
    </Button>
  );
}

const styles = () => ({
  contentPlanningPublicIdContainer: {
    background: " #5555f4",
    color: "white",
    padding: "2px 8px",
    borderRadius: "5px",
    width: 'fit-content'
  },
});
