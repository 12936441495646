import { Box } from "@mui/material";
import * as React from "react";

function DrawerPanel({ children }) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        boxSizing: "border-box",
        padding: "2rem",
        width: "100%",
      }}
    >
      {children}
    </Box>
  );
}

export default DrawerPanel;
