import { createBrowserRouter, RouterProvider } from "react-router-dom";
import * as React from "react";
import { observer } from "mobx-react";
import { HOME_PATH, METRICS_PATH } from "../routes";
import LoginScreen from "../screens/Login";
import App from "../App";
import { useApplication } from "../providers/applicationProvider";
import NotFoundScreen from "../screens/NotFoundScreen";
import MetricsScreen from "../screens/MetricsScreen";

function AppRouter() {
  const application = useApplication();

  const unauthenticatedRoutes = createBrowserRouter([
    {
      path: "*",
      element: <LoginScreen />,
    },
  ]);

  const authenticatedRoutes = createBrowserRouter([
    {
      path: "*",
      element: <NotFoundScreen />,
    },
    {
      path: METRICS_PATH,
      element: <MetricsScreen />,
    },
    {
      path: HOME_PATH,
      element: <App />,
    },
  ]);

  const routes = application.userIsAuthenticated
    ? authenticatedRoutes
    : unauthenticatedRoutes;

  return <RouterProvider router={routes} />;
}

export default observer(AppRouter);
